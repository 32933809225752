.Records {
  text-align: center;
  overflow: hidden;
  height: 100vh;
  max-width: 100%;
}

.Records h2 {
  padding: 2vh 1vh;
}

.Records ul {
  width: 15%;
  background-color: #dddddd;
  position: fixed;
}
.Records li {
  height: 7.6923vh;
  line-height: 7.6923vh;
}

.Records .list-title {
  font-size: 2vw;
  background-color: #fff944;
}
.Records li a {
  font-size: 1.2vw;
  display: block;
  color: #000;
  text-decoration: none;
}

.Records li a:hover:not(.active) {
  background-color: #555;
  color: white;
}

.Records li a.active {
  background-color: rgb(5, 153, 183);
  color: white;
}

.records-page {
  margin-left: 15%;
  padding: 10px 20px;
  height: 100vh;
}
.Records .link-to-homepage a {
  background-color: #ff9797;
}
