:root {
  --CARD-WIDTH: clamp(38px, min(8.7vh, 5.5vw), 90px);
  --CARD-HEIGHT: calc(var(--CARD-WIDTH) * 1.4078);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: arial, sans-serif;
  /* max-width: 100%; */
}

h1 {
  font-size: clamp(40px, 12vh, 3.5rem);
  padding-bottom: 10px;
}

h2 {
  font-size: clamp(24px, 9vh, 3rem);
  padding-top: 1vh;
}

h3 {
  font-size: clamp(16px, 6vh, 2rem);
  padding-top: 1vh;
}

h4 {
  font-size: clamp(12px, 4.5vh, 1.5rem);
  padding-top: 1vh;
}

.WaitingRoom ul {
  font-size: clamp(20px, 7vh, 2rem);
  padding-top: 1vh;
}

button {
  cursor: pointer;
}

label {
  font-size: 1.2rem;
}

input[type="text"] {
  font-size: 1.2rem;
  border: none;
  border-bottom: 1px solid rgb(126, 126, 126);
}

.App {
  width: 100vw;
  height: 100vh;
}

.Homepage {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50vw 50vw;
  justify-content: center;
  align-items: center;
}

.Homepage form {
  font-size: 36px;
}

.Homepage button,
.WaitingRoom button {
  font-size: 1.2rem;
  padding: 6px 40px;
  border-radius: 10%;
}
.WaitingRoom h1 {
  text-align: center;
}

.homepageColumn {
  display: inline-block;
  text-align: center;
}

.alignLeft {
  display: inline-block;
  text-align: left;
}

.WaitingRoom {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-template-columns: 50vw 50vw;
  align-items: center;
}

.Game {
  overflow: hidden;
  user-select: none;
  margin: 0;
  display: grid;
  grid-template-rows: 78vh 22vh;
  grid-template-areas:
    "opponent-side"
    "player-side";
  justify-content: center;
  align-items: center;
  background-color: green;
}

.GameEnded {
  background-color: yellow;
}

.UserCards {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.UserSide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  grid-area: player-side;
}

.OpponentsSide {
  display: grid;
  grid-template-rows: 22vh 56vh;
  grid-template-columns: 16.66vw 5vw 11.66vw 16.66vw 16.66vw 11.66vw 5vw 16.66vw;
  grid-template-areas:
    "top-hand top-hand top-hand top-hand top-hand top-hand top-hand top-hand"
    "left-hand left-hand field field field field right-hand right-hand";
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: clip;
}

.UserSide .user-turn-text {
  height: calc(var(--CARD-WIDTH) / 2);
  font-size: calc(var(--CARD-WIDTH) / 2);
}

.OpponentHand {
  display: flex;
  align-items: center;
  padding-top: 11px;
}
.PlayerSpot {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.OpponentName {
  height: 2vw;
  font-size: 2vw;
}

.PlayedCards {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  grid-area: field;
  justify-content: center;
}

.CardBack,
.CardBack img {
  height: var(--CARD-HEIGHT);
  width: var(--CARD-WIDTH);
}

.table-button {
  padding: 0;
  position: absolute;
  border-radius: 50%;
  border: 1px solid black;
  width: 40px;
  height: 40px;
  font-size: 20px;
  top: 5px;
  right: 5px;
  z-index: 3;
}

.new-game-button {
  margin-top: 15px;
  border-radius: 7px;
  border: 1px solid black;
  width: 100%;
  height: min(20vh, 120px);
  font-size: min(10vh, 60px);
  z-index: 2;
  background-color: rgb(110, 255, 255);
}

#info-button {
  padding: 0;
  position: absolute;
  border: 1px solid black;
  width: 100px;
  height: 50px;
  font-size: 20px;
  top: 10px;
  left: 10px;
  z-index: 2;
}
#records-button {
  padding: 0;
  position: absolute;
  border: 1px solid black;
  width: 100px;
  height: 50px;
  font-size: 20px;
  top: 10px;
  right: 10px;
  z-index: 2;
}
