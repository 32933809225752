.card {
  cursor: not-allowed;
  position: relative;
  height: var(--CARD-HEIGHT);
  width: var(--CARD-WIDTH);
  border: 1px solid black;
  border-radius: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(var(--CARD-HEIGHT) * 0.7);
  background-color: white;
}

.card.red {
  color: red;
}

.card.black {
  color: black;
}

.card.valid {
  cursor: pointer;
}

.card::before,
.card::after {
  position: absolute;
  content: attr(data-value);
  font-size: calc(var(--CARD-HEIGHT) * 0.155);
}

.card::before {
  top: calc(var(--CARD-HEIGHT) * 0.03);
  left: calc(var(--CARD-WIDTH) * 0.07);
}

.card::after {
  bottom: calc(var(--CARD-HEIGHT) * 0.03);
  right: calc(var(--CARD-WIDTH) * 0.07);
  transform: rotate(180deg);
}
