.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.popup-inner {
  position: relative;
  padding: 1vw;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.call-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.call-popup-inner {
  padding: 10px;
  background-color: white;
  text-align: center;
}
.call-popup-inner input {
  padding: 1vw;
  font-size: 4vh;
  background-color: white;
}
.call-popup-input {
  width: 30vw;
}
.call-popup-inner label {
  padding: 1vw;
  font-size: 4vh;
}

.call-popup-inner input::-webkit-outer-spin-button,
.call-popup-inner input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.call-popup-inner input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.invalid-call-text {
  color: red;
  text-align: center;
  font-size: 4vh;
}

.last-turn-popup-container {
  font-size: 2vw;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.last-turn-button {
  padding: 0;
  position: absolute;
  border-radius: 50%;
  border: 1px solid black;
  width: 40px;
  height: 40px;
  font-size: 20px;
  top: 5px;
  left: 5px;
  z-index: 3;
}

.laleo-popup {
  z-index: 1;
}

.laleo-cards {
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 1vw;
}
.laleo-cards .card {
  cursor: pointer;
}
.laleo-cards .card.selected {
  background-color: rgba(255, 255, 0, 0.5);
}

.laleo-button {
  border-radius: 0;
  border: 1px solid black;
  border-radius: 7px;
  width: 100%;
  height: min(8vh, 50px);
  font-size: min(4vh, 25px);

  background-color: rgba(255, 255, 0, 0.5);
}
.laleo-text {
  text-align: center;
  font-size: min(4vh, 25px);
  padding-bottom: 1vw;
}
